<template>
  <div>
    <div>
      <div>
        <!-- 背景大的 -->
        <bigbg
          url="static/srbanner/UdRDyqRg.webp"
          bigtext="文旅项目规划运营"
          smalltext="赋能文旅项目，整合资源，推动产业升级"
        ></bigbg>
        <!-- 景区管理app -->
        <div class="twobig">
          <div class="center_first">
            <h2>景区管理APP</h2>
            <p>
              通过运营系统的优化，把运营从线下联通到线上，推动景区资源管理和旅游要素的量化和集成，实现信息共享数字化、资源保护数字化、运营管理智能化、旅游服务个性化
            </p>
            <div class="img_first"></div>
          </div>
        </div>
        <!-- over -->
        <!-- 空间运营 -->
        <div class="threebg">
          <h2>空间运营</h2>
          <p>空间运营包含旅游景区运营、旅游度假区运营、商街运营等</p>
          <div class="threeimgs">
            <div class="texthead">旅游景区/旅游度假区运营</div>
            <div class="boxthrees">
              <!-- 左右箭头 -->

              <!-- 四个盒子 -->
              <div class="yi">
                <div class="imgyi"></div>
                <div class="textyi">
                  <div class="one">驻场服务</div>
                  <div class="two">
                    派遣景区工程师长驻旅游景区/度假区，进行技术输出
                  </div>
                </div>
              </div>
              <div class="er">
                <div class="imgyi1"></div>
                <div class="textyi1">
                  <div class="one1">运营咨询</div>
                  <div class="two1">
                    派遣景区工程师研判问题、编制方案、解读培训、模拟实施，指导运营系统的优化和提升
                  </div>
                </div>
              </div>
              <div class="san">
                <div class="imgyi2"></div>
                <div class="textyi2">
                  <div class="one2">委托运营</div>
                  <div class="two2">
                    派驻景区工程师长驻景区/度假区，进行技术输出并推动景区实现增量发展
                  </div>
                </div>
              </div>
              <div class="si">
                <div class="imgyi3"></div>
                <div class="textyi3">
                  <div class="one3">合作经营</div>
                  <div class="two3">
                    通过合资或独资的方式组建旅游景区/度假区管理公司，共同或独立经营旅游景区/度假区
                  </div>
                </div>
              </div>
              <!-- over -->
            </div>
          </div>
        </div>
        <!-- over -->
        <!-- 902旅游标准化 -->
        <div class="fourbig">
          <h2>旅游标准化</h2>
          <p>
            标准化创建辅导系列:
            5A级旅游景区、4A级旅游景区、国家级旅游度假区、省级旅游度假区、全域旅游示范区
          </p>
          <!-- 左右布局 -->
          <div class="bigboxss">
            <div class="onebox">
              <div class="imgs"></div>
              <div class="box">
                <p>标准化专项整改提升</p>
                <div>
                  主要是以服务质量与环境质量方面整改提升为主，提供全程指导服务，包括问题解决方案编制、模拟运行、定期指导、驻场执行、工程师巡访、品牌沙龙等
                </div>
              </div>
            </div>
            <div style="height:30px"></div>
            <div class="onebox">
              <div class="box">
                <p>标准化创建辅导</p>
                <div>
                  主要以品牌创建为主，提供全程咨询辅导服务，包括申报材料编制、问题解决方案编制、模拟运行、定期指导、驻场执行、旅游建设质量管理、工程师巡访、品牌沙龙等
                </div>
              </div>
              <div class="images"></div>
            </div>
          </div>
        </div>
        <!-- over -->
        <!-- 5优秀案例 -->
        <div class="fivebgimg">
          <div class="center_first">
            <h2>优秀案例</h2>
            <p>
              24家5A级旅游景区，9家国家级旅游度假区，12家4A级省级旅游度假区
            </p>
            <div
              class="fivesimg"
              @mouseover="mouseOver"
              @mouseleave="mouseLeave"
            >
              <div class="left" @click="prev">
                <img src="./photo/leftclick.png" alt="" />
              </div>
              <!-- 102  70 -->
              <div class="bigboxsimg">
                <div
                  v-for="(item, index) in imgs"
                  :style="config5[index]"
                  :key="item.cover"
                >
                  <img :src="item.cover" style="width: 100%; height: 100%" />
                </div>
              </div>
              <!-- 滚动条 -->
              <div class="tiaozi">
                <div
                  :class="this.centerInfo.index == 0 ? 'bigblue' : 'yuan'"
                ></div>
                <div
                  :class="this.centerInfo.index == 1 ? 'bigblueer' : 'yuaner'"
                ></div>
                <div
                  :class="this.centerInfo.index == 2 ? 'bigblue' : 'yuan'"
                ></div>
              </div>
              <!--  -->
              <div class="right" @click="next">
                <img src="./photo/rightclick.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <!-- over -->
        <!-- 组后 -->
      </div>
    </div>
    <!--  -->
    <!-- 底部组件 -->

    <Header></Header>
    <Footer></Footer>
  </div>
</template>
<script>
import bigbg from "../component/bigbg.vue";
// 导入头部底部组件
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
  name: "trapoer",
  components: {
    bigbg,
    Header,
    Footer,
  },
  data() {
    return {
      times: "",
      currentIndex: 1, //当前中间imgs数组中index
      imgs: [
        {
          id: "lunboyi",
          index: 0,
          cover: "static/srbanner/uepYPPjU.webp",
        },
        {
          id: "lunboer",
          index: 1,
          cover: "static/srbanner/HbbEBRUM.webp",
        },
        {
          id: "lunbosan",
          index: 2,
          cover: "static/srbanner/eN0gwvAy.webp",
        },
      ],
      config5: [
        {
          id: "B",
          position: "absolute",
          width: "28%",
          height: "83%",
          top: "8.3%",
          left: "0",
          opacity: 1,
          zIndex: 2,
          boxShadow: "2px 4px 12px 2px rgba(0, 0, 0, 0.1)",
          borderRadius: "6px",
          transition: "1s",
          lineHeight: "83%",
          overflow: "hidden",
        },
        {
          id: "center",
          position: "absolute",
          width: "87%",
          height: "100%",
          top: "0px",
          left: "6.5%",
          opacity: 1,
          zIndex: 4,
          borderRadius: "6px",
          transition: "1s",
          overflow: "hidden",
        },
        {
          id: "D",
          position: "absolute",
          width: "28%",
          height: "83%",
          top: "8.3%",
          left: "72%",
          opacity: 1,
          zIndex: 2,
          boxShadow: "2px 4px 12px 2px rgba(0, 0, 0, 0.1)",
          lineHeight: "83%",
          borderRadius: "6px",
          transition: "1s",
          overflow: "hidden",
        },
      ],
      centerInfo: 1,
    };
  },
  methods: {
    // 滑动上一个
    prev(index) {
      // this.imgs.unshift(this.imgs.pop());
      this.config5.push(this.config5.shift());
      this.currentIndex = this.currentIndex - 1;
      if (this.currentIndex < 0) {
        this.currentIndex = this.imgs.length - 1;
      }
      this.centerCard();
    },
    // 滑动下一个
    next() {
      // this.imgs.push(this.imgs.shift());
      this.config5.unshift(this.config5.pop());
      this.currentIndex = this.currentIndex + 1;
      if (this.currentIndex > this.imgs.length - 1) {
        this.currentIndex = 0;
      }
      this.centerCard();
    },
    // 计算中间卡片信息
    centerCard() {
      this.centerInfo = this.imgs[this.currentIndex];
      this.$emit("centerInfo", this.centerInfo);
    },
    //鼠标移入
    mouseOver() {
      clearTimeout(this.times);
    },
    //鼠标移出
    mouseLeave() {
      this.circulation();
    },
    //循环播放轮播图
    circulation() {
      this.times = setInterval(() => {
        this.next();
      }, 2000);
    },
  },
  created() {
    this.centerCard();
  },
  mounted() {
    this.circulation();
  },
  beforeDestroy() {
    clearInterval(this.times);
  },
};
</script>
<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
}
.twobig {
  height: 919px;
  background-color: #fff;
  background-image: url("./photo/twobig.png");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.center_first:after {
  content: "";
  display: block;
  clear: both;
}
.center_first,
.threebg,
.fourbig {
  font-family: PingFangSC-Medium, PingFang SC;
  padding-top: 80px;
  h2 {
    font-size: 40px;
    font-weight: 500;
    color: #333333;
    line-height: 56px;
    letter-spacing: 1px;
  }
  p {
    margin: 0 auto;
    width: 1197px;
    margin-top: 24px;
    font-size: 20px;
    font-weight: 400;
    color: #565656;

    letter-spacing: 1px;
    font-family: PingFangSC-Regular, PingFang SC;
    margin-bottom: 60px;
  }
  .threeimgs {
    height: 624px;
    background-image: url("./photo/ABzUjJtl.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    padding-top: 105px;
    .texthead {
      margin: 0 auto;
      width: 475px;
      height: 56px;
      font-size: 40px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 56px;
      letter-spacing: 1px;
    }
    .boxthrees {
      margin: 60px 310px 0;
      width: 1300px;
      height: 300px;
      position: relative;
      .left {
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 50px;
        height: 50px;
        background: rgba(255, 255, 255, 0.18);
        color: #fff;
        text-align: center;
        line-height: 50px;
        .lt {
          width: 16px;
          height: 32px;
          background: url("./photo/oneleftone.png") no-repeat center;
          background-size: 100% 100%;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          margin: auto;
        }
        .boxleftnew {
          width: 8px;
          height: 8px;
          border-radius: 1px solid red !important;
        }
      }
      .right {
        border-radius: 50%;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 50px;
        height: 50px;
        background: rgba(255, 255, 255, 0.18);
        color: #fff;
        text-align: center;
        line-height: 50px;
        .gt {
          width: 16px;
          height: 32px;
          background: url("./photo/onerightone.png") no-repeat center;
          background-size: 100% 100%;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          margin: auto;
        }
      }
      .yi {
        width: 585px;
        height: 149px;
        margin-left: 73px;
        position: relative;
        .imgyi {
          position: absolute;
          width: 57px;
          height: 56px;
          background-image: url("./photo/yi.png");
          background-size: 100% 100%;
          top: 40px;
          left: 23px;
        }
        .textyi {
          .one {
            position: absolute;
            left: 112px;
            top: 40px;
            border: none;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }
          .two {
            position: absolute;
            left: 112px;
            top: 72px;
            width: 433px;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            text-align: left;
          }
        }
      }
      .er {
        width: 585px;
        height: 149px;
        position: absolute;
        right: 0;
        top: 0;
        .imgyi1 {
          position: absolute;
          width: 57px;
          height: 56px;
          background-image: url("./photo/er.png");
          background-size: 100% 100%;
          top: 40px;
          // left: 23px;
        }
        .textyi1 {
          .one1 {
            position: absolute;
            // left: 112px;
            left: 90px;
            top: 40px;
            border: none;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }
          .two1 {
            position: absolute;
            width: 433.5px;
            // left: 112px;
            left: 90px;
            top: 72px;
            text-align: left;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
      .san {
        width: 585px;
        height: 149px;
        margin-left: 73px;
        position: relative;
        .imgyi2 {
          position: absolute;
          width: 57px;
          height: 56px;
          background-image: url("./photo/san.png");
          background-size: 100% 100%;
          top: 40px;
          left: 23px;
        }
        .textyi2 {
          .one2 {
            position: absolute;
            left: 112px;
            top: 40px;
            border: none;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }
          .two2 {
            position: absolute;
            width: 433px;
            left: 112px;
            top: 72px;
            text-align: left;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
      .si {
        width: 585px;
        height: 149px;
        position: absolute;
        right: 0;
        top: 150px;
        .imgyi3 {
          position: absolute;
          width: 57px;
          height: 56px;
          background-image: url("./photo/si.png");
          background-size: 100% 100%;
          top: 40px;
          // left: 23px;
        }
        .textyi3 {
          .one3 {
            position: absolute;
            left: 90px;
            top: 40px;
            border: none;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }
          .two3 {
            position: absolute;
            width: 433.5px;
            left: 90px;
            top: 72px;
            text-align: left;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
    }
  }
}
.img_first {
  width: 597px;
  height: 566px;
  margin: 0 auto;
  background-image: url("./photo/MfFRAQl1.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
// 3
.threebg {
  height: 884px;
}
//4
.fourbig {
  height: 902px;
  background-image: url("./photo/fourbgimg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  .bigboxss {
    width: 1200px;
    margin: 0 auto;
    .onebox {
      display: flex;
      width: 1200px;
      height: 324px;
      background: #ffffff;
      box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
      border-radius: 6px;
      .imgs {
        width: 600px;
        height: 324px;
        background-image: url("./photo/k2BWYBxt.webp");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
      }
      .box {
        width: 600px;
        height: 324px;
        p {
          margin-top: 64px;
          margin-left: 48px;
          margin-bottom: 24px;
          width: 600px;

          font-size: 32px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;

          letter-spacing: 1px;
          text-align: left;
        }
        div {
          margin-top: 64px;
          margin-left: 48px;
          margin-bottom: 24px;
          width: 504px;

          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;

          text-align: left;
        }
      }
      .images {
        width: 600px;
        height: 324px;
        background-image: url("./photo/BuZUrNmV.webp");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
      }
    }
  }
}
//5
.fivebgimg {
  margin-top: 100px;
  height: 744px;
  background: #f6f8fb;
  .fivesimg {
    position: relative;
    width: 1312px;
    margin: 0 auto;
    height: 360px;

    .left {
      position: absolute;
      width: 64px;
      height: 64px;
      background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
      box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04),
        0px 2px 8px 0px rgba(0, 0, 0, 0.06);
      border-radius: 32px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      img {
        position: absolute;
        width: 40px;
        height: 40px;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
    .right {
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      width: 64px;
      height: 64px;
      border-radius: 32px;
      background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
      box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04),
        0px 2px 8px 0px rgba(0, 0, 0, 0.06);
      img {
        position: absolute;
        width: 40px;
        height: 40px;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
    .left:hover {
      cursor: pointer;
    }
    .right:hover {
      cursor: pointer;
    }
    .bigboxsimg {
      overflow: hidden;
      height: 360px;
      width: 1140px;
      position: relative;
      margin: 0 auto;
    }
    .tiaozi {
      display: flex;
      margin-top: 16px;
      margin-left: 620px;

      .yuaner {
        margin-left: 8px;
        margin-right: 8px;
        width: 8px;
        height: 8px;
        background: #d1d1d1;
        border-radius: 4px;
      }
      .yuan {
        width: 8px;
        height: 8px;
        background: #d1d1d1;
        border-radius: 4px;
      }
      .bigblue {
        width: 36px;
        height: 8px;
        background: #007aff;
        border-radius: 4px;
      }
      .bigblueer {
        margin-left: 8px;
        margin-right: 8px;
        width: 36px;
        height: 8px;
        background: #007aff;
        border-radius: 4px;
      }
    }
  }
}
</style>
